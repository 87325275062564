import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Cta from "../components/cta";
import Hero from "../components/hero";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import "../css/base-style.css";

function Terms({ data }) {
    let d = data.contentfulPageRich;
    return (
        <Layout>
            <SEO title={d.title} />

            <Hero
                props={{
                    padding: `10`,
                    title: data.contentfulPageRich.title,
                    desc: ``,
                }}
            />

            <section className="bg-white dark:bg-gray-800">
                <div
                    className="w-full max-w-6xl mx-auto px-4 py-8 md:px-16 md:py-16 paragraph overflow-y-auto dark:bg-gray-700 rounded-lg border border-gray-200 dark:border-gray-700 my-10"
                    style={{ height: `750px` }}
                >
                    {renderRichText(d.content)}
                </div>
            </section>

            <Cta />
        </Layout>
    );
}

Terms.propTypes = {
    data: PropTypes.object,
};

export const query = graphql`
    {
        contentfulPageRich(label: { eq: "shipping-terms" }) {
            title
            label
            content {
                raw
            }
        }
    }
`;

export default Terms;
